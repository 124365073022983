<template>
  <div id="newsCreate">
    <v-toolbar flat><v-btn rounded @click="$router.go(-1)" icon><v-icon>mdi-chevron-left</v-icon></v-btn></v-toolbar>
    <v-container>
     <v-card>
        <v-form ref="form" v-model="form.valid">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field label="タイトル" :value="form.subject" :rules="form.subject_rule" @change="value => form.subject = value"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea label="本文" :value="form.content" :rules="form.content_rule" @change="value => form.content = value" auto-grow outlined></v-textarea>
              </v-col>
              <v-col>
                <v-switch v-model="form.release_flag" label="公開・非公開" :false-value="2" :true-value="1" hide-details="" class="mt-0"></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="$router.go(-1)" text>キャンセル</v-btn>
            <v-btn v-promise-btn @click="updateDataWithAPI" color="primary"><v-icon small>mdi-plus-circle</v-icon>登録</v-btn><!-- TODO: Submit button continuous transmission problem -->
            <v-spacer />
          </v-card-actions>
          <v-overlay :value="isLoading" absolute>
          <v-progress-circular indeterminate size="64" />
        </v-overlay>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'newsCreate',
  data: function () {
    return {
      isEdit: false,
      isLoading: false,
      news: {},
      form: {
        valid: true,
        subject_rule: [
          v => !!v || 'タイトルは必ず入力してください'
        ],
        content_rule: [
          v => !!v || 'コンテンツは必ず入力してください'
        ],
        subject: '',
        content: '',
        release_flag: 2
      },
      flagSelected: {},
      releaseFlags: [
        {
          name: '公開',
          flag: 1
        },
        {
          name: '非公開',
          flag: 2
        }
      ]
    }
  },
  methods: {
    updateDataWithAPI: function () {
      const _this = this
      if (_this.$refs.form.validate()) {
        _this.isLoading = true
        return new Promise(function (resolve, reject) { // TODO: Submit button continuous transmission problem
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/news`, _this.form)
            .then(res => {
              _this.$store.commit('setFlashSuccess', 'お知らせを作りました。')
              _this.$router.push({ name: 'admin:news_index' })
              _this.isLoading = false
              resolve(res) // TODO: Submit button continuous transmission problem
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              _this.isLoading = false
              reject(err) // TODO: Submit button continuous transmission problem
            })
        })
      }
    }
  }
}
</script>
